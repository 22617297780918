<template>
  <div class="about">
    <banner-hero :bannerHero="bannerHero" />
    <steps />
  </div>
</template>
<script>
import BannerHero from '@/components/BannerHero';
import Steps from '@/components/RegisterTrademark/Steps';

export default {
  name: 'RegisterTrademark',
  components: { Steps, BannerHero },
  data() {
    return {
      bannerHero: BannerHero.methods.Object(
        'Registra tu marca',
        null,
        'https://i.imgur.com/mHtjo7a.jpeg',
        ' ',
        'Registrar tu marca tiene múltiples beneficios, como la confianza que se crea en el cliente, ' +
          'ya que aumenta la imagen del producto o servicio que ofreces, así como la credibilidad de la calidad de ' +
          'su empresa, por otra parte, el valor de su marca en el mercado aumenta, pues crecen las posibilidades de ' +
          'comercializar y aumentar sus ingresos económicos, pues usted tendrá todo el control legal sobre su marca y ' +
          'otros activos intangibles.' +
          '<br />' +
          '<br />' +
          'Al registrar su marca se encuentra seguro de que su imagen, nombre o singo no serán utilizados ' +
          'por otra persona, y en caso de que exista un uso indebido de su marca o signos distintivos, ' +
          'tiene todo el derecho de actuar legalmente o bien en caso de que alguna persona o empresa ' +
          'ya tenga una marca similar, podremos protegerte y actuar de manera preventiva contra el ' +
          'cobro de regalías por el uso indebido de un marca ya registrada, evitando el inicio de un pleito jurídico.'
      ),
    };
  },
};
</script>
