<template>
  <div class="container-fluid bg-light col-12 col-sm-10 col-md-8" :class="$style.component">
    <div class="row mx-4">
      <div
        class="col-lg-6 col-md-src/components/BaseLayout/Footer.vue12 my-4"
        v-for="(step, index) in steps"
        :key="index"
      >
        <div class="card p-4 h-100 card-round">
          <div class="card-body p-4 h-100 text-white">
            <h5 class="card-title h5 m-0 semi-bold-600">{{ step.title }}</h5>
            <h6 class="card-title h6 m-0 semi-bold-600 text-white-50">{{ step.subtitle }}</h6>
            <p class="card-text pt-2 mb-1 light-300 text-justify" v-html="step.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  data() {
    return {
      steps: [
        {
          title: 'Solicita tu búsqueda de disponibilidad de marca',
          subtitle: 'Paso 1',
          description:
            'Por medio de la búsqueda, nos aseguraremos que la marca que se pretende registrar se encuentre disponible y no se encuentra en conflicto con otras, <b>te damos los resultados en 24 horas.</b>' +
            '<br />' +
            '<br />' +
            '<b>Costo:</b>' +
            '<br />' +
            '<b>$500 pesos</b> IVA incluido, sin importar el número de clases en las que tengamos que buscar.' +
            '<br />' +
            '<br />' +
            'Este servicio no te compromete a realizar el registro con nosotros.',
        },
        {
          title: 'Proporciona tu información (titular de marca)',
          subtitle: 'Paso 2',
          description:
            'Te enviaremos un pequeño cuestionario, con la finalidad de conocer los detalles de la marca y datos importantes del su titular.',
        },
        {
          title: 'Realiza tu pago',
          subtitle: 'Paso 3',
          description:
            'Te enviaremos por mensaje el método de pago' +
            '<br />' +
            '<br />' +
            '<b>Costo:</b>' +
            '<br />' +
            '<b>$7,500 pesos</b> por clase.' +
            '<br />' +
            'En caso de requerir factura + IVA ' +
            'el pago por anticipado es requerido.',
        },
        {
          title: 'Tu marca estará integrada y en proceso de registro',
          subtitle: 'Paso 4',
          description:
            'Preparamos y presentamos tu solicitud en 48 horas o menos.' +
            '<br />' +
            '<br />' +
            'Damos seguimiento y, al terminar, te mandamos tu título.',
        },
      ],
    };
  },
};
</script>

<style lang="scss" module>
.component :global {
  .card-round {
    border-radius: 20px;
    background-color: #0477bf;
  }
}
</style>
